import React from "react"

import Layout from "../../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import data from "../../data/about"
import footerStyles from "../../components/footer/footer.module.scss"

import { Helmet } from "react-helmet"
export default function About() {
  const { facility } = useStaticQuery(
    graphql`
      query aboutImg {
        facility: file(relativePath: { eq: "facility.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed(width: 400) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.title + " | Clean Air Filter"}</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{data.content.section1.h1.head}</span>{" "}
            {data.content.section1.h1.tail}
          </span>
        </h1>

        <article className="w-60">
          <div className="float-right">
            <Img
              style={{ borderRadius: 5 }}
              fluid={facility.childImageSharp.fluid}
              alt="will put"
            ></Img>
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <p style={{ marginBottom: "0.25rem" }}>
                <Link
                  to="/contact"
                  style={{ marginRight: "0.75rem" }}
                  className="primary red"
                >
                  {data.content.section1.a1}
                </Link>
              </p>
              {/* <p style={{ marginBottom: '0.25rem' }}>
                            <Link
                                to='/contact'
                                className="primary red">{data.content.section1.a2}</Link>
                        </p> */}
            </div>
          </div>

          <p dangerouslySetInnerHTML={{ __html: data.content.section1.p1 }}></p>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li6,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li7,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li8,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.ul.li9,
              }}
            ></li>
          </ul>
        </article>
      </section>
      <article className="w-60">
        <section>
          <h2>Office Hours</h2>
          <p>Monday - Thursday 8:30 am - 4:00 pm</p>
        </section>

        <section>
          <h2>{data.content.section2.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li6,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li7,
              }}
            ></li>
          </ul>
        </section>

        <section>
          <h2>{data.content.section3.h2}</h2>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.p1 }}></p>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.p2 }}></p>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.p3 }}></p>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.p4 }}></p>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.p5 }}></p>
          <p dangerouslySetInnerHTML={{ __html: data.content.section3.a }}></p>
        </section>
        <section>
          <p className={footerStyles.copyRight}>
            <span>{"©2020 Clean Air Filter"}</span>{" "}
            <span className={footerStyles.bullet}>•</span>{" "}
            <a href="https://bluespacecreative.com/">
              {"branding & website development by"} <strong>blue</strong>space
              creative, inc.
            </a>
          </p>
        </section>
      </article>
    </Layout>
  )
}
